html {
  overflow-y: scroll !important;
}

body {
  /*background: #f7f7f7;*/
  /*
  background: rgb(235, 235, 235);
  background: linear-gradient(135deg, rgba(235, 235, 235, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(235, 235, 235, 1) 100%);
  */
  background: rgb(212, 215, 218);
  background: linear-gradient(128deg, rgba(212, 215, 218, 1) 0%, rgba(227, 226, 226, 1) 51%, rgba(212, 215, 218, 1) 100%);

}